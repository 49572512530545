import React from 'react';
import { DetailsComponent } from "./components/Details.component";

const MePage = () => {
    return (
        <DetailsComponent />
    )
}

export default MePage
