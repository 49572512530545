import { WorksComponent } from "./components/Works.component"
import React from 'react';

const ExperiencePage = () => {
    return (
        <WorksComponent />


    )
}
export default ExperiencePage